<template>
  
    <section class="appie-about-8-area pt-20 pb-20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title mb-0 pb-0">
                        <h3 class="appie-title">We build everything <br/> that's required to run the show !!!</h3>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-lg-7">
                    <div class="appie-about-8-box">
                        <h3 class="title">Free Box Office Movie<br/> Ticket Selling Software</h3>
                        <p>Want to systemized your theatre business <br/> with just Box Office sales? we have application for you.</p>
                        <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                        <div class="thumb">
                            <img src="@/assets/images/about-thumb-4.png" alt="Movie Ticket Selling Software" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="appie-about-8-box">
                        <h3 class="title">Online Movie Ticket <br/> Booking with Multi-Screen</h3>
                        <p>With 11 years of experience, we built Theatre Owner-friendly customized movie ticket booking software.</p>
                        <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                        <div class="thumb mr-30">
                            <img src="@/assets/images/about-thumb-5.png" alt="Online Movie Ticket Booking Software" />
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>