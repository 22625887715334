<template>
  <!--====== APPIE PRICING PART START  ======-->
    
    <section class="appie-pricing-area pt-90 pb-90">
        <div class="container-fluid">
            <div class="lg-pl-50 lg-pr-50">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="appie-section-title text-center">
                            <h3 class="appie-title">{{title}}</h3>
                            <p>{{description}}</p>
                        </div>               
                    </div>
                </div>
                               
              
                <!-- Pricing one -->
                <div class="row pricing-one__single mt-5 border" style="padding:5px !important;">
                    <div class="col-lg-9 p-3">
                        <div class="pricig-heading" style="padding-bottom:5px !important;">
                        <h6 class="cust-txt-1">Roftr Rental (Online E-Commerce).</h6>
                        <p>Suitable for Start-up E-commerce business.</p>
                        </div>
                        <div class="mt-3">
                            <ul class="row">
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Online Shopping site (Own Domain)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Customer Login/Orders History/Track and Order</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Own Payment Gateway (Commission from 0.40%)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> SMS/EMail Order/Alerts</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Category/Sub-cat/Products Listing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Size/Color/Product descriptions</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Success/Pending/S2S Payments</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Invoice/Label Printing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Customized Sales Reports</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Customer Order Management</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Data Backup/Export</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Website SEO/Webmaster</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Google Analytics/Console</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-1"></i> Cloud Server DigitalOcean</li>
                                
                            </ul>
                            
                         </div>
                    </div>
                    
                    <div class="col-lg d-flex p-3 border bg-light justify-content-center">
                        <div class="text-center align-self-center">
                            <!-- <div class="pricig-heading border-0"> 
                                <h6>Rental</h6>
                                <div class="price-range"><sup>&#8377;</sup> <span>6500</span><p>/Monthly</p></div>
                                <p>Inclusive of all charges.</p>
                            </div> -->
                            <div class="pricing-btn">
                                <a class="main-btn" href="tel:+919790403333">Interested? Call Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Pricing one -->
                
                               
                <!-- Pricing two -->
                <div class="row pricing-one__single mt-5 border" style="padding:5px !important;">
                    <div class="col-lg-9 p-3">
                        <div class="pricig-heading" style="padding-bottom:5px !important;"><h6 class="cust-txt-2">Roftr Regular (Online E-Commerce).</h6>
                        <p>User friendly customized Online shopping as per your needs with all necessary features.</p>
                        </div>
                        <div class="mt-3">
                            <ul class="row">
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Built in Roftr Framework</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Online Shopping site (Own Domain)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Customer Login (OTP &amp; Social Media)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Customer Orders/Track and Order/Whislist</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Own Payment Gateway (Commission from 0.40%)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> API Integration Shipping/Stores/more</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> SMS/EMail Order/Alerts/Notifications</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Store/Vendor/Brand/Stocks Listing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Category/Sub-cat/Products Listing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Size/Color/Unique Product Specifications</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Success/Pending/ LinkToPay/S2S Payments</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Offline/POS Sales</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Invoice/Label/Greatings</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Customizes Discount Codes</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Customized Sales/Stocks/Performance Reports</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Customer Order/Analytics/Enquiry/Performance </li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Owner/Manager/ Accounts/Stock/ Shipping Level Access</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Data Backup/Export/Import</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Website SEO/Analytics/Webmaster</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Google Analytics/Console/ MerchantCenter/PlayStore</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Market Place Product Feeds</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> DigitalOcean/ AWS/ GoogleCloud/ Cloud Server</li>
                                
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-2"></i> Multi-Lingual Support</li>
                                
                            </ul>
                            
                         </div>
                    </div>
                    
                    <div class="col-lg d-flex p-3 border bg-light justify-content-center">
                        <div class="text-center align-self-center">
                            <!-- <div class="pricig-heading border-0"> 
                                <h6>Regular</h6>
                                <div class="price-range"><sup>&#8377;</sup> <span>xxx,xxx</span><p>/one-time</p></div>
                                <p>Inclusive of all charges.</p>
                            </div> -->
                            <div class="pricing-btn">
                                <a class="main-btn" href="tel:+919790403333">Interested? Call Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Pricing two -->
                
                <!-- Pricing three -->
                <div class="row pricing-one__single mt-5 border" style="padding:5px !important;">
                    <div class="col-lg-9 p-3">
                        <div class="pricig-heading" style="padding-bottom:5px !important;"><h6 class="cust-txt-3">Roftr Advanced (Retail &amp; E-Commerce).</h6> 
                        <p>Built with world leading technology React/Vue js/Nuxt/Laravel Framework, fast and automated.</p>
                        </div>
                        <div class="mt-3">
                            <ul class="row">
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Built in React/Vue js/Nuxt/Laravel Framework</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Online Shopping site (Own Domain)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Customer Login (OTP &amp; Social Media)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Customer Orders/Track and Order/Whislist</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Own Payment Gateway (Commission from 0.40%)</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> API Integration Shipping/Stores/more</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> WhatsApp/SMS/EMail Order/Alerts/ Notifications/Marketting</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Store/Vendor/Brand/Stocks Listing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Category/Sub-cat/Products Listing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Size/Color/Unique Product Specifications</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Success/Pending/ LinkToPay/S2S Payments</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Owner/Manager/ Accounts/Stock/ Shipping Level Access</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Offline/POS Sales</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Invoice/Label/Greatings/ Barcode Printing</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Discount Codes/Seasonal Offer Sale</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Customized Sales/Stocks/Performance Reports</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Customer Order/Analytics/ Enquiry/Performance  Management</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Data Backup/Export/Import</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Website SEO/PWA/Analytics/ Webmaster/Audit</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Google Analytics/Console/ MerchantCenter/PlayStore</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Market Place Product Feeds</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> DigitalOcean/ AWS/ GoogleCloud/ Cloud Server</li>
                                <li class="col-md-6 mt-2"><i class="fal fa-check cust-icon-3"></i> Multi-Lingual Support</li>
                                
                            </ul>
                            
                         </div>
                    </div>
                    
                    <div class="col-lg d-flex p-3 border bg-light justify-content-center">
                        <div class="text-center align-self-center">
                            <!-- <div class="pricig-heading border-0"> 
                                <h6>Advanced</h6>
                                <div class="price-range"><sup>&#8377;</sup> <span>xxx,xxx</span><p>/one-time</p></div>
                                <p>Inclusive of all charges.</p>
                            </div> -->
                            <div class="pricing-btn">
                                <a class="main-btn" href="tel:+919790403333">Interested? Call Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Pricing three -->
            </div>
        </div>
    </section>
    
    <!--====== APPIE PRICING PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type: String,
        },
        description:{
            type: String,
        }
    },
    data(){
        return{
            switchPlan:true
        }
    },
    methods:{
         change_plan(){
            this.switchPlan = !this.switchPlan
        },
    }

}
</script>

<style>

</style>